// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-candidatura-js": () => import("./../../../src/pages/candidatura.js" /* webpackChunkName: "component---src-pages-candidatura-js" */),
  "component---src-pages-certificado-certificate-id-js": () => import("./../../../src/pages/certificado/[certificate_id].js" /* webpackChunkName: "component---src-pages-certificado-certificate-id-js" */),
  "component---src-pages-cursos-course-slug-iteration-name-perfil-equipa-js": () => import("./../../../src/pages/cursos/[course_slug]/[iteration_name]/perfil-equipa.js" /* webpackChunkName: "component---src-pages-cursos-course-slug-iteration-name-perfil-equipa-js" */),
  "component---src-pages-cursos-course-slug-iteration-name-preview-js": () => import("./../../../src/pages/cursos/[course_slug]/[iteration_name]/preview.js" /* webpackChunkName: "component---src-pages-cursos-course-slug-iteration-name-preview-js" */),
  "component---src-pages-cursos-index-js": () => import("./../../../src/pages/cursos/index.js" /* webpackChunkName: "component---src-pages-cursos-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-participar-equipa-js": () => import("./../../../src/pages/participar-equipa.js" /* webpackChunkName: "component---src-pages-participar-equipa-js" */),
  "component---src-pages-perguntas-frequentes-js": () => import("./../../../src/pages/perguntas-frequentes.js" /* webpackChunkName: "component---src-pages-perguntas-frequentes-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-sobre-nos-js": () => import("./../../../src/pages/sobre-nos.js" /* webpackChunkName: "component---src-pages-sobre-nos-js" */),
  "component---src-pages-termos-e-condicoes-js": () => import("./../../../src/pages/termos-e-condicoes.js" /* webpackChunkName: "component---src-pages-termos-e-condicoes-js" */),
  "component---src-templates-curso-candidatura-jsx": () => import("./../../../src/templates/curso-candidatura.jsx" /* webpackChunkName: "component---src-templates-curso-candidatura-jsx" */),
  "component---src-templates-curso-confirmacao-jsx": () => import("./../../../src/templates/curso-confirmacao.jsx" /* webpackChunkName: "component---src-templates-curso-confirmacao-jsx" */),
  "component---src-templates-curso-detalhes-jsx": () => import("./../../../src/templates/curso-detalhes.jsx" /* webpackChunkName: "component---src-templates-curso-detalhes-jsx" */)
}

