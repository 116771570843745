import React from "react";
import PropTypes from "prop-types";
import { Auth0Provider} from "@auth0/auth0-react";
import { navigate } from "gatsby";

/*const LoginHandler = ({ children }) => {
  return children;
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  /*useEffect(() => {
    if (isLoading || !isAuthenticated) {
      return;
    }
/*
    /*(async () => {
      try {
        const token = await getAccessTokenSilently();

        await fetch(`${process.env.API_URL}/auth`, {
          method: "GET",
          headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
      } catch (e) {
        console.error(e);
      }
    })();
  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  return children;
};*/

const AuthProvider = ({ children }) => {
  const onRedirectCallback = (appState) =>
    navigate(appState?.returnTo || '/', { replace: true });

  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN}
      clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
      audience={process.env.GATSBY_AUTH0_AUDIENCE}
      redirectUri={process.env.GATSBY_AUTH0_CALLBACK_URL}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;

AuthProvider.propTypes = {
  children: PropTypes.node
};
